import React, { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import "./ContactForm.css";

const MyForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    comment: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle the form submission, e.g., validation and API call
  };

  return (
    <Box
      mt={5}
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box className="row">
        <TextField
          name="name"
          label="Name"
          value={formData.name}
          onChange={handleChange}
          variant="outlined"
          className="mui-textField"
        />
        <TextField
          name="email"
          label="Email"
          value={formData.email}
          onChange={handleChange}
          variant="outlined"
          required
          className="mui-textField"
        />
      </Box>
      <TextField
        name="phoneNumber"
        label="Phone number"
        value={formData.phoneNumber}
        onChange={handleChange}
        variant="outlined"
        className="mui-textField"
        style={{ width: "70%", marginBottom: "2rem" }}
      />
      <TextField
        name="comment"
        label="Comment"
        value={formData.comment}
        onChange={handleChange}
        variant="outlined"
        multiline
        rows={4}
        fullWidth
        className="mui-textField"
        style={{ width: "70%" }}
      />
      <Button
        type="submit"
        variant="contained"
        className="submitButton"
        sx={{
          position: "relative",
          overflow: "hidden",
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "black",
          borderRadius: 0,
          padding: "0.6rem 2rem",
          marginTop: "2rem",
          transition:
            "color 0.3s ease-in-out, background-color 0.3s ease-in-out",

          color: "white",
          backgroundColor: "black",
          "&:hover": {
            color: "black",
            backgroundColor: "transparent",
          },
          "& .button-text": {
            position: "relative",
            zIndex: 1,
          },
        }}
      >
        <span className="button-text">Submit</span>
      </Button>
    </Box>
  );
};

export default MyForm;
