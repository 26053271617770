import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Typography, Container } from "@mui/material";
import Navbar from "./pages/navbar-component/Navbar";
import Home from "./pages/home/Home";
const About = () => <Typography variant="h2">About Page</Typography>;
const Users = () => <Typography variant="h2">Users Page</Typography>;

const App = () => {
  return (
    <Router>
      <Navbar />
      <Container maxWidth={false} style={{ padding: 0 }}>
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/users" element={<Users />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Container>
    </Router>
  );
};

export default App;
