import React from "react";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";

const BottomLayer = styled(Card)(({ theme }) => ({
  width: "25rem",
  height: "25rem",
  marginTop: "1rem",
  position: "absolute",
  boxShadow: theme.shadows[6],
  borderRadius: theme.shape.borderRadius,
}));

const TopLayer = styled(Card)(({ theme }) => ({
  width: "25rem",
  height: "25rem",
  marginLeft: "1rem",
  padding: "10px 10px 0 0",
  position: "relative",
  zIndex: 1,
  boxShadow: theme.shadows[6],
  borderRadius: theme.shape.borderRadius,
}));

type DoubleLayeredImageCardProps = {
  image: string;
};

const DoubleLayeredImageCard: React.FC<DoubleLayeredImageCardProps> = ({
  image,
}) => {
  return (
    <div style={{ position: "relative", marginBottom: "2rem" }}>
      <BottomLayer />
      <TopLayer>
        <img
          src={image}
          alt="Decorative"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </TopLayer>
    </div>
  );
};

export default DoubleLayeredImageCard;
