import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Button, SvgIcon, Box } from "@mui/material";
import { ReactComponent as Logo } from "./../../assets/pixel-pursuit-deals-logo.svg";
import Title from "./../../assets/pixel-pursuit-deals-title.png";
import "./Navbar.css";

const Navbar: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <AppBar
      sx={{
        backgroundColor: isScrolled
          ? "rgba(255, 255, 255, 0.7)"
          : "transparent",
        backdropFilter: isScrolled ? "blur(10px)" : "none",
        boxShadow: "none",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        height: isScrolled ? "64px" : "auto",
        transition: "all 0.3s ease-in-out",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between", minHeight: "100%" }}>
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <SvgIcon
            component={Logo}
            viewBox="0.00 0.00 500 500"
            sx={{
              width: isScrolled ? "4rem" : "8rem",
              height: isScrolled ? "4rem" : "8rem",
              ml: isScrolled ? 5 : 15,
              transition: "all 0.3s ease-in-out",
            }}
          />
          {!isScrolled && (
            <Box
              component="img"
              sx={{
                height: "2rem",
              }}
              src={Title}
              alt="Logo Title"
            />
          )}
        </Box>

        {/* Set button text color to black always */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button sx={{ color: "black", mr: 5 }} component={Link} to="/">
            Home
          </Button>
          {/* <Button sx={{ color: "black", mr: 5 }} component={Link} to="/about">
            About
          </Button>
          <Button sx={{ color: "black", mr: 30 }} component={Link} to="/users">
            Users
          </Button> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
