import React, { useState } from "react";
import { Box, Typography } from "@mui/material";

interface ExpandableTextProps {
  title: string;
  content: React.ReactNode;
}

const ExpandableText: React.FC<ExpandableTextProps> = ({ title, content }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "1rem 0",
        "&:hover": {
          cursor: "pointer",
        },
        ".content-box": {
          maxHeight: isHovered ? "500px" : "0px",
          overflow: "hidden",
          transition: "max-height 1s ease-in-out",
        },
      }}
    >
      <Typography
        variant="h6"
        component="h3"
        sx={{
          fontWeight: 300,
          fontSize: "1.5rem",
          fontFamily: "'Albert Sans', sans-serif",
        }}
      >
        {title}
      </Typography>
      <Box
        className="content-box"
        sx={{
          maxWidth: "80%",
          marginTop: "0.5rem",
          textAlign: "center",
        }}
      >
        {content}
      </Box>
    </Box>
  );
};

export default ExpandableText;
