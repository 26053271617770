import React from "react";
import { Box } from "@mui/material";
import ExpandableText from "./ExpandableText";
import Separator from "../separator/Separator";

interface TitleContent {
  title: string;
  content: React.ReactNode; // This can be string, JSX.Element, and more
}

interface HoverExpandTextProps {
  titles: TitleContent[];
}

const HoverExpandText: React.FC<HoverExpandTextProps> = ({ titles }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "auto",
      }}
    >
      {titles.map((item, index) => (
        <React.Fragment key={index}>
          {index > 0 && <Separator width="50%" thickness="1px" />}
          <ExpandableText
            title={item.title}
            content={item.content}
          ></ExpandableText>
        </React.Fragment>
      ))}
    </Box>
  );
};

export default HoverExpandText;
