import React from "react";
import { Box, Typography } from "@mui/material";
import DoubleLayeredImageCard from "../double-layered-image-card/DoubleLayeredImageCard";

const ContentImageRow = ({
  title,
  text,
  image,
  imageLeft,
}: {
  title: string;
  text: string;
  image: string;
  imageLeft: boolean;
}) => {
  const textSection = (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        height: "10rem",
      }}
    >
      <Typography
        variant="h4"
        component="h2"
        sx={{
          fontWeight: 800,
          fontSize: "3.5rem",
          margin: "0 10rem 0.75rem 10rem",
          fontFamily: "'Albert Sans', sans-serif",
          textAlign: "left",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontWeight: 200,
          fontSize: "1.2rem",
          margin: "0 10rem",
          textAlign: "left",
        }}
      >
        {text}
      </Typography>
    </Box>
  );

  const imageSection = (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <DoubleLayeredImageCard image={image} />
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "space-evenly",
        height: "80vh",
      }}
    >
      {imageLeft ? imageSection : textSection}
      {imageLeft ? textSection : imageSection}
    </Box>
  );
};

export default ContentImageRow;
