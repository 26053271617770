import React from "react";
import { Button, Typography, Box } from "@mui/material";
import "./Home.css";
import section1Image from "./../../assets/section-1-image.jpg";
import section2Image from "./../../assets/section-2-image.jpg";
import section3Image from "./../../assets/section-3-image.jpg";
import ContentImageRow from "../../components/content-image-row/ContentImageRow";
import Separator from "../../components/separator/Separator";
import HoverExpandTexts from "../../components/expandable-text/HoverExpandTexts";
import ContactForm from "../../components/contact-form/ContactForm";

const Home = () => {
  const titles = [
    {
      title: "GUARANTEED",
      content: (
        <Typography>
          <b>Success</b> is mutual in a <b>true partnership</b>, we share the
          risks together.
          <br />
          We only win if <b>You</b> win.
        </Typography>
      ),
    },
    {
      title: "RESULTS",
      content: (
        <Typography>
          Delivering <b>results</b> is our top commitment — <b>actions</b> speak
          louder than words.
        </Typography>
      ),
    },
    {
      title: "LOCAL",
      content: (
        <Typography>
          We operate <b>openly</b>, far from the anonymity of a call center. As
          a <b>local business</b>, we ensure you have access to our{" "}
          <b>support</b> whenever required.
        </Typography>
      ),
    },
    {
      title: "SPECIALIZED",
      content: (
        <Typography>
          We excel through <b>specialization</b>, ensuring expertise in familiar
          industries to deliver <b>guaranteed outcomes</b>.
        </Typography>
      ),
    },
  ];

  return (
    <Box>
      <Box
        className="home-background"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            height: "60rem",
          }}
        >
          <Typography
            variant="h3"
            component="h1"
            sx={{
              fontWeight: 800,
              fontSize: "3.75rem",
              fontFamily: "'Albert Sans', sans-serif",
              margin: "0 20rem 2rem 20rem",
            }}
          >
            Grow Your Business, Audience, and Turnovers. Guaranteed!
          </Typography>
          <Button
            sx={{
              position: "relative",
              overflow: "hidden",
              color: "black",
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: "black", // Keep the border color as black
              borderRadius: 0,
              padding: "0.6rem 2rem",
              transition:
                "color 0.3s ease-in-out, background-color 0.3s ease-in-out", // Transition for color and background color
              backgroundColor: "transparent", // Initial background color
              "&:hover": {
                color: "white",
                backgroundColor: "black", // Background color on hover
              },
              "& .button-text": {
                position: "relative",
                zIndex: 1, // Ensure text is above background
              },
            }}
          >
            <span className="button-text">I Need that</span>
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "30vh",
          margin: "4rem 0 0.5rem 0",
        }}
      >
        <Typography
          variant="h6"
          component="h3"
          sx={{
            fontWeight: 450,
            fontSize: "2.5rem",
            fontFamily: "'Albert Sans', sans-serif",
          }}
        >
          Marketing is important...
        </Typography>
        <Typography
          sx={{
            fontWeight: 250,
            fontSize: "1.2rem",
            fontFamily: "'Albert Sans', sans-serif",
            margin: "0 33rem",
            textAlign: "center",
          }}
        >
          ...but there are already 101 things on your to-do list. And they are
          all important too!
        </Typography>
      </Box>
      <Separator width="100%" thickness="2px" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "10vh",
        }}
      >
        <Typography
          variant="h6"
          component="h3"
          mt={5}
          sx={{
            fontWeight: 150,
            fontSize: "2.5rem",
            fontFamily: "'Albert Sans', sans-serif",
          }}
        >
          How to get the best out of the marketing?
        </Typography>
      </Box>
      <Box>
        <ContentImageRow
          title="Do it yourself?"
          text="You could do it yourself, but is it worth the time and effort? This will consume all of your time and energy, instead use it to develop your business. You do what you do best! Why waste the potential to enhance your business services, supply chain or even networking opportunities?"
          image={section1Image}
          imageLeft={false}
        />
        <ContentImageRow
          title="Hire Staff?"
          text="Hiring staff is a nightmare, isn’t it? Going through CV’s and job applications trying to find the best applicant to hire. Teaching them what to do and how to do it, and getting them in the business atmosphere. Imagine you go through all that and in the end they end up being frauds or couldn’t make up to the requirements you want."
          image={section2Image}
          imageLeft={true}
        />
        <ContentImageRow
          title="Hire an Agency?"
          text="This is what you should do. Let me tell you a secret about big marketing agencies. Small and local businesses are assigned to the new and cheapest of their staff. Leaving you with little to no success in marketing, and wasting your time. We specialize in working with local business."
          image={section3Image}
          imageLeft={false}
        />
      </Box>
      <Separator width="100%" thickness="1.5px" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "10vh",
        }}
      >
        <Typography
          variant="h6"
          component="h3"
          sx={{
            fontWeight: 150,
            fontSize: "2.5rem",
            fontFamily: "'Albert Sans', sans-serif",
          }}
        >
          Here is why you should work with us
        </Typography>
      </Box>
      <HoverExpandTexts titles={titles} />
      <Box
        mt={10}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "10vh",
        }}
      >
        <Typography
          variant="h6"
          component="h3"
          sx={{
            fontWeight: 150,
            fontSize: "2.5rem",
            fontFamily: "'Albert Sans', sans-serif",
          }}
        >
          Contact Us For A Free Marketing Analysis
        </Typography>
        <ContactForm />
      </Box>
    </Box>
  );
};

export default Home;
